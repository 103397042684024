import { Message } from "components/assistant/Assistant";
import Cookies from "js-cookie";
import { BACKEND_URL } from "config/getUrl";
import { validateClient } from "./authRequests";

const API_URL = BACKEND_URL;

export const queryAssistant = async (
  question: string,
  myCode: string,
  id: string,
  type: string,
  language: string,
): Promise<[error: string] | [error: undefined, messages: Message[]]> => {
  // Validate the tokens
  const validateResponse = await validateClient();

  // Check if the user is still connected
  if (!validateResponse.connected) {
    return ["It was not possible to find an access or refresh token."];
  }

  // Get the accessToken
  const accessToken = Cookies.get("accessToken");

  // Check that the access token is not undefined
  if (!accessToken) {
    return ["It was not possible to find an access token."];
  }

  const editorSession = process.env.REACT_APP_DEMO
    ? Cookies.get("editorSession") || ""
    : "";

  // Sending to the server
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      question,
      myCode,
      id,
      type,
      language,
      session: editorSession,
    }),
  };

  try {
    const response = await fetch(`${API_URL}/api/assistant/query`, options);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const text = await response.json();
    return [undefined, text];
  } catch (error) {
    console.error("Error fetching code:", error);
    return [error as string]
  }
}

export const markCodeAssistant = async (
  question: string,
  myCode: string,
  id: string,
  type: string,
  language: string
): Promise<[error: string] | [error: undefined, messages: Message[]]> => {
  // Validate the tokens
  const validateResponse = await validateClient();

  // Check if the user is still connected
  if (!validateResponse.connected) {
    return ["It was not possible to find an access or refresh token."];
  }

  // Get the accessToken
  const accessToken = Cookies.get("accessToken");

  // Check that the access token is not undefined
  if (!accessToken) {
    return ["It was not possible to find an access token."];
  }

  const editorSession = process.env.REACT_APP_DEMO
    ? Cookies.get("editorSession") || ""
    : "";
  
  const options = {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      question,
      myCode,
      id,
      type,
      language,
      session: editorSession,
    }),
  };
  try {
    const response = await fetch(
      `${API_URL}/api/assistant/mark-code`,
      options
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const text = await response.json();
    
    return [undefined, text];
  } catch (error) {
    console.error("Error checking code:", error)
    return [error as string];//{complete: false, message: error as string, error: error as string}
  }
}

export const convertJsonToScratch = async (
  body: string
) => {
  // Validate the tokens
  const validateResponse = await validateClient();

  // Check if the user is still connected
  if (!validateResponse.connected) {
    return ["It was not possible to find an access or refresh token."];
  }

  // Get the accessToken
  const accessToken = Cookies.get("accessToken");

  // Check that the access token is not undefined
  if (!accessToken) {
    return ["It was not possible to find an access token."];
  }

  const {scratchblocks} = await fetch(`${BACKEND_URL}/api/scratch/jsonToText`, {
    method: "POST",
    headers:{
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    },
    body: body
  }).then(response => response.json())
  
  return scratchblocks;
}