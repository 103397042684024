import { useEffect, useState } from "react";

interface VideoPIPProps {
  className?: string;
  isOpen: boolean;
  videoUrl: string;
  width: number;
  maxWidth?: number;
  maxHeight?: number;
  iframeRef: React.RefObject<HTMLIFrameElement>;
}

const Video: React.FC<VideoPIPProps> = ({
  iframeRef,
  className = "",
  isOpen,
  videoUrl,
  width,
  maxWidth = Infinity,
  maxHeight = Infinity,
}) => {
  // States definition
  const [videoWidth, setVideoWidth] = useState(Math.min(width, maxWidth));
  const [videoHeight, setVideoHeight] = useState(Math.min(width * (9 / 16), maxHeight));
  
  /**
   * Set the video width based on the container width
   */
  useEffect(() => {
    setVideoWidth(Math.min(width, maxWidth, videoHeight * 16 / 9));
  }, [width, videoHeight])

  /**
   * Set the video height based on the container width
   */
  useEffect(() => {
    setVideoHeight(Math.min((width * 9) / 16, maxHeight));
  }, [width])

  // To be improved: change for a regex expression
  if (!isOpen || !videoUrl.includes("vimeo.com/")) return null;

  const embedUrl = videoUrl.replace("vimeo.com/", "player.vimeo.com/video/");

  return (
    <div className={className}>
      <iframe
        ref={iframeRef}
        title="video"
        src={embedUrl}
        allow="autoplay; fullscreen; picture-in-picture"
        style={{
          width: `${videoWidth}px`,
          height: `${videoHeight}px`,
        }}
      ></iframe>
    </div>
  );
};

export default Video;
