import React, { useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";
import { MdOpenInNew } from "react-icons/md";
import { AssistantAllButtonTypes, CodeFile } from "utils/interfaces";
import { platform } from "config/platform";
import Player from "@vimeo/player";
import AssistantPopup from "components/Editor/assistant/AssistantPopup";

interface MinecraftEditorHeaderProps {
  name: string;
  AIAssistant: "littleC" | "jakita";
  activeItem: "editor" | "assistant";
  setActiveItem: React.Dispatch<React.SetStateAction<"assistant" | "editor">>;
  showAssistant: boolean;
  assistantPopupState:
    | "closed"
    | "popup"
    | "popupNoCode"
    | "expanded"
    | "fullscreen";
  setAssistantPopupState: React.Dispatch<
    React.SetStateAction<
      "closed" | "popup" | "popupNoCode" | "expanded" | "fullscreen"
    >
  >;
  assistantPopupClickedButton: AssistantAllButtonTypes;
  setAssistantPopupClickedButton: React.Dispatch<
    React.SetStateAction<AssistantAllButtonTypes>
  >;
  codeFiles: CodeFile[];
}

const MinecraftEditorHeader: React.FC<MinecraftEditorHeaderProps> = ({
  name,
  AIAssistant,
  activeItem,
  setActiveItem,
  showAssistant,
  assistantPopupState,
  setAssistantPopupState,
  assistantPopupClickedButton,
  setAssistantPopupClickedButton,
  codeFiles,
}) => {
  // States definition

  // Define a reference for the video iframe
  const videoIframeRef = useRef<HTMLIFrameElement>(null);

  // Define a navigator state
  const navigate = useNavigate();

  // Get the parameters from the url
  const { moduleOrProject } = useParams();
  // Get a state if we are receiving a state variable from another url
  const { state } = useLocation();

  // Return to home url
  const goToDashboard = () => {
    if (state) {
      navigate("/home", {
        state: {
          // State for SuperAdmin.tsx
          activeItem: "lessons",
          // State for Lessons.tsx
          activeLesson: moduleOrProject,
          navRegister: state ? state.navRegister : [],
        },
      });
    } else {
      navigate("/home");
    }
  };

  /**
   * Function used to pause video
   */
  const pauseVideo = () => {
    if (videoIframeRef.current) {
      const player = new Player(videoIframeRef.current);
      player.pause();
    }
  };

  /**
   * Function that defines the contextual behaviour of the back button
   */
  const handleClickBack = () => {
    if (activeItem === "assistant") setActiveItem("editor");
    else if (activeItem === "editor") goToDashboard();
  };

  return (
    <>
      <div className="w-full h-[155px] px-10 relative flex items-center z-20">
        {/* Logo and back button */}
        <div className="min-w-[480px] left-10 flex items-center gap-8">
          <img
            className={`${
              platform === "codeflix" ? "w-[124px] h-10" : "w-[97.80px] h-16"
            } cursor-pointer`}
            src="/logo.svg"
            onClick={goToDashboard}
          />
          <div className="w-px self-stretch bg-neutral-200" />
          <div className="w-full justify-start items-center gap-6 flex">
            <div className="flex-col justify-start items-center gap-2 inline-flex">
              <div
                className="w-12 h-12 p-3 bg-neutral-50 hover:bg-neutral-100 ease-in-out duration-150 rounded-full justify-center items-center inline-flex cursor-pointer z-10"
                onClick={handleClickBack}
              >
                <div className="w-6 h-6 relative">
                  <GoArrowLeft className="w-full h-full fill-dc-secondary-600" />
                </div>
              </div>
              <div className="justify-start items-start gap-2 inline-flex">
                <div className="text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide select-none">
                  Back
                </div>
              </div>
            </div>
            <div className="text-center text-neutral-600 text-[23px] font-bold font-sans leading-7">
              {activeItem === "assistant" ? "Feedback" : name}
            </div>
          </div>
        </div>

        {/* Title */}
        <div className="flex-grow flex flex-col justify-center items-center">
          {activeItem === "assistant" && (
            <div className="text-center text-neutral-500 text-base font-normal font-sans leading-[19px]">
              {AIAssistant === "jakita"
                ? "Tutoring with Big J"
                : AIAssistant === "littleC"
                ? "Tutoring with Little C"
                : "Tutoring with Cody"}
            </div>
          )}
        </div>

        {/* Assistant and video buttons */}
        {/* <div className="right-10 flex items-center mr-3 z-10"> */}
          {/* Conditional rendering for Assistant */}
          {/* {showAssistant && (
            <AssistantPopup
              assistant={platform === "deadly-coders" ? AIAssistant : "cody"}
              state={assistantPopupState}
              setState={setAssistantPopupState}
              clickedButton={assistantPopupClickedButton}
              setClickedButton={setAssistantPopupClickedButton}
              codeFiles={codeFiles}
              goToDashboard={goToDashboard}
              language={""}
            />
          )}
        </div> */}
        <div className="h-fit p-3 rounded-full justify-center items-center gap-1 inline-flex">
          <div className="flex-col justify-center items-center gap-2 inline-flex">
            <a
              target="_blank"
              href="https://education.minecraft.net/en-us/get-started/download"
              className="p-3 bg-dc-secondary-600 hover:bg-dc-secondary-700 ease-in-out duration-150 rounded-full justify-center items-center gap-2 inline-flex cursor-pointer"
            >
              <div className="w-6 h-6 relative">
                <MdOpenInNew className="w-full h-full fill-white" />
              </div>
            </a>
            <div className="text-center text-dc-secondary-600 text-xs font-semibold font-['Poppins'] uppercase leading-[14px] tracking-wide select-none">
              Open Minecraft
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MinecraftEditorHeader;
