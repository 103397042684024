import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  ClassListType,
  Course,
  Module,
  Program,
  Project,
} from "utils/interfaces";
import { capitaliseFirstChar } from "utils/utils";
import TabBar from "./utils/TabBar";
import Settings from "./settings-view/Settings";
import PopUpPanel from "./pop-up-panels/PopUpPanel";
import lessonInformation from "./utils/lessonInformation";
import { getCollectionRequest } from "services/apiRequests";
import { useNavigate } from "react-router-dom";
import AnimatedLessonCards from "./AnimatedLessonCards";
import { programLanguagesList } from "config/optionValues";

interface LessonsListProps {
  userRole: string;
  lessonListToShow: string;
  searchQuery: string;
  backPressedToggle: boolean;
  setShowSideBarMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setLessonListToShow: React.Dispatch<React.SetStateAction<string>>;
  headerRef: React.MutableRefObject<any>;
  lessonsRef: any;
  quizzesRef: any;
  ref: any;
}

interface LessonsListRefProps {
  setShowTabs: (bool: boolean) => void;
  setSelectedTab: (tab: number) => void;
  setFromCreateNew: (bool: boolean) => void;
  handleSave: () => void;
  getLessonListToShow: () => string;
  setShowAddToLesson: (bool: boolean) => void;
  setSelectedData: (record: Program | Course | Project | Module | null) => void;
  getLinkedData: () => [string, string];
}

const LessonsList: React.FC<LessonsListProps> = forwardRef<
  LessonsListRefProps,
  LessonsListProps
>(
  (
    {
      userRole,
      lessonListToShow,
      searchQuery,
      backPressedToggle,
      setShowSideBarMenu,
      setLessonListToShow,
      headerRef,
      lessonsRef,
      quizzesRef,
    },
    ref
  ) => {
    // States definition
    const [selectedData, setSelectedData] = useState<
      Program | Course | Project | Module | null
    >(null);
    const [programs, setPrograms] = useState<Program[]>([]);
    const [courses, setCourses] = useState<Course[]>([]);
    const [projects, setProjects] = useState<Project[]>([]);
    const [modules, setModules] = useState<Module[]>([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [showTabs, setShowTabs] = useState(false);
    const [tabsOptions, setTabsOptions] = useState([
      capitaliseFirstChar(lessonListToShow),
      "Settings",
    ]);
    const [fromCreateNew, setFromCreateNew] = useState(false);
    const [showSavedSuccessfully, setShowSavedSuccessfully] = useState(false);
    const [showAddClasses, setShowAddClasses] = useState(false);
    const [showAddToLesson, setShowAddToLesson] = useState(false);
    const [showAddPopUp, setShowAddPopUp] = useState(false);
    // State for module when clicking on add button
    const [selectedModuleId, setSelectedModuleId] = useState("");
    // States used for contextual creation
    const [linkedId, setLinkedId] = useState("");
    const [linkedLesson, setLinkedLesson] = useState("");
    // State for coordination lesson navigation
    const [navRegister, setNavRegister] = useState<
      {
        lessonListToShow: string;
        selectedData: Program | Course | Project | Module | null;
        record: Program | Course | Project | Module | null;
      }[]
    >([]);
    // States for add lessons to class
    const [lessonIdToAssignToClass, setLessonIdToAssignToClass] = useState("");
    const [selectedClasses, setSelectedClasses] = useState<ClassListType[]>([]);
    const [lessonTypeToAssign, setLessonTypeToAssign] = useState("");
    // State used for the saved successful pop up
    const [successfulLesson, setSuccessfulLesson] = useState("");
    // Loading states
    const [isLoadingPrograms, setIsLoadingPrograms] = useState(true);
    const [isLoadingCourses, setIsLoadingCourses] = useState(true);
    const [isLoadingProjects, setIsLoadingProjects] = useState(true);
    const [isLoadingModules, setIsLoadingModules] = useState(true);

    // Hook used for navigation to other pages
    const navigate = useNavigate();

    // Reference to the settings module
    const settingsRef = useRef();

    /**
     * Define the list of functions that can be used from external components
     * using a reference
     */
    useImperativeHandle(ref, () => ({
      // Outer function to change the tab
      setShowTabs(bool: boolean) {
        setShowTabs(bool);
      },
      setSelectedTab(tab: number) {
        setSelectedTab(tab);
      },
      setFromCreateNew(bool: boolean) {
        setFromCreateNew(bool);
      },
      handleSave() {
        if (settingsRef.current)
          (settingsRef.current as any).handleSaveLesson();
      },
      getLessonListToShow() {
        return lessonListToShow;
      },
      setShowAddPopUp(bool: boolean) {
        setShowAddPopUp(bool);
      },
      setShowAddToLesson(bool: boolean) {
        setShowAddToLesson(bool);
      },
      setSelectedData(record: Program | Course | Project | Module | null) {
        setSelectedData(record);
      },
      getData() {
        getData();
      },
      getLinkedData() {
        return [linkedId, linkedLesson];
      },
      onBackButtonClick() {
        onBackButtonClick();
      },
      addCurrentToNavRegister() {
        setNavRegister([
          ...navRegister,
          { lessonListToShow, selectedData, record: null },
        ]);
      },
      startFromLastNavRegister(
        navRegisterInput: {
          lessonListToShow: string;
          selectedData: Program | Course | Project | Module | null;
          record: Program | Course | Project | Module | null;
        }[]
      ) {
        // Set the previous navRegister
        setNavRegister(navRegisterInput);

        // Navigate to the last state
        const lastRegister = navRegisterInput[navRegisterInput.length - 1];
        const recordToClick = lastRegister.record;
        const lessonList = lastRegister.lessonListToShow;

        if (recordToClick) handleCardClick(recordToClick, 0, lessonList, false);
      },
    }));

    /**
     * Root definition
     */
    useEffect(() => {
      if (lessonListToShow || !showAddClasses) getData();
      fromCreateNew
        ? setTabsOptions(["Contents", "Settings"])
        : setTabsOptions([capitaliseFirstChar(lessonListToShow), "Settings"]);
    }, [lessonListToShow, selectedData, showAddClasses]);

    /**
     * Function used to prevent hide the tab-search bar when coming back from the
     * editor
     */
    useEffect(() => {
      // If we are coming back as a student, then force it to be true
      if (["student"].includes(userRole))
        lessonsRef.current.setShowTabSearchBar(true);
      // For the master admin case
      else if (["master admin"].includes(userRole)) {
        // If we have selected data (nested type)
        if (selectedData) {
          // Show the tabs
          setShowTabs(true);

          // Hide the lateral tab
          setShowSideBarMenu(false);

          // Set the header options
          headerRef.current
            .lessons()
            .setOptionButtons(
              lessonInformation(lessonListToShow).parent,
              selectedTab
            );
        }
      }
    }, [userRole]);

    /**
     * Set the title and description when we have selected data
     */
    useEffect(() => {
      // Check if the selected program is a program. Null means root view
      if (selectedData) {
        // If the user is a student, then display the title and description
        // as a navigation record
        if (userRole === "student") {
          // Get the list of navigation data, to get the name of each lesson clicked
          const lessonTitles =
            navRegister.map((info, index: number) => (
              <React.Fragment key={index + 1}>
                {index > 0 && <span> / </span>}
                {/* It's "index + 1" because we are adding the "Lesson" root later */}
                <span
                  className="underline cursor-pointer hover:text-neutral-800"
                  onClick={() =>
                    comeBackToSpecificLesson(navRegister, index + 1)
                  }
                >
                  {info.record ? info.record.title : "-"}
                </span>
              </React.Fragment>
            )) || [];

          // Add the root lesson
          lessonTitles.unshift(
            <React.Fragment key={0}>
              <span
                className="underline cursor-pointer hover:text-neutral-800"
                onClick={() => comeBackToMainView("programs")}
              >
                Lessons
              </span>
              <span> / </span>
            </React.Fragment>
          );

          // And set the header title and description
          headerRef.current.setHeaderTitle(
            capitaliseFirstChar(lessonListToShow)
          );
          headerRef.current.setHeaderDescription(lessonTitles);
        } else {
          // Set the header title and description
          headerRef.current.setHeaderTitle(selectedData.title);
          headerRef.current.setHeaderDescription(selectedData.description);
        }
      } else {
        // Modify automatically for the student case
        if (userRole === "student") {
          headerRef.current.setHeaderTitle(
            capitaliseFirstChar(lessonListToShow)
          );
          headerRef.current.setHeaderDescription("Lessons");
        }
        // For the rest of the roles
        else {
          // Apply this if we are not creating a new lesson
          if (!fromCreateNew) {
            headerRef.current.setHeaderTitle(
              capitaliseFirstChar(lessonListToShow)
            );
            headerRef.current.setHeaderDescription(
              `List of current ${lessonListToShow}`
            );
          }
        }
      }
    }, [selectedData, lessonListToShow, backPressedToggle, userRole]);
    
    /**
     * Definition of the function executed after creating a lesson
     * in the database
     */
    const afterCreateLessons = (successfulLesson: string) => {
      // Show create successful pop up message
      setShowSavedSuccessfully(true);

      setLessonListToShow(lessonInformation(lessonListToShow).parent);

      // And come back to the previous view menu
      onBackButtonClick();
      // if (!fromCreateNew) onBackButtonClick();
      // else comeBackToMainView(lessonInformation(lessonListToShow).parent);

      // Update data
      getData();

      // Define the name of the lesson to show in the saved successful pop up panel
      setSuccessfulLesson(successfulLesson);
    };

    /**
     * Function that defines the root view
     */
    const comeBackToMainView = (
      lessonToShow = lessonListToShow,
      selectedDataInput: typeof selectedData = null
    ) => {
      // Unselect data (in the case we selected some)
      setSelectedData(selectedDataInput);
      // Activate the side bar menu
      setShowSideBarMenu(true);
      // Hide the inner tab
      setShowTabs(false);
      // And come back to the list
      setSelectedTab(0);
      // And reset the view
      setFromCreateNew(false);
      // And set the list to display
      setLessonListToShow(lessonToShow);
      // And ensure the navigation history is empty
      setNavRegister([]);

      // Show the main tab
      lessonsRef.current.setShowTabSearchBar(true);
      // Delete back button
      headerRef.current.setHeaderBackButton(false);

      // And set the header
      headerRef.current.lessons().handleComeBackHeader(lessonToShow);
    };

    /**
     * Function used to come back to specific lesson when clicking on a
     * description route in the student view
     * @param index Represents the position of the item clicked.
     * @returns void
     */
    const comeBackToSpecificLesson = (
      navRegisterInput: typeof navRegister,
      index: number
    ) => {
      // Cut the rest of the navigated register
      const newRegister = navRegisterInput.slice(0, index);

      // Get the selected state that will be the new current state
      // (Equivalent to the newRegister[-1])
      const currentState = navRegisterInput[index - 1];

      // Double check that the previous state exists
      if (!currentState) return;

      // Get the current lesson info
      const currentLessonInfo = lessonInformation(
        currentState.lessonListToShow
      );

      // Unselect data (in the case we selected some)
      setSelectedData(currentState.record);
      // And set the list to display
      setLessonListToShow(currentLessonInfo.child);
      // Set the new navigation history without the previous state
      setNavRegister(newRegister);
    };

    /**
     * Function that defines the behaviour on click back button
     */
    const onBackButtonClick = () => {
      // First of all, ensure that fromCreateNew state is false
      setFromCreateNew(false);

      // And undefine the contextual Id and lesson
      setLinkedId("");
      setLinkedLesson("");

      // First of all, check if the navigation history list is not empty
      if (navRegister.length <= 1) {
        if (navRegister.length === 1) {
          comeBackToMainView(
            navRegister[0].lessonListToShow,
            navRegister[0].selectedData
          );

          return;
        }
        // Come back to the main view (based on the parent view)
        comeBackToMainView(lessonInformation(lessonListToShow).parent);
        return;
      }

      // Define the new navigation history and the previous state
      const newRegister = [...navRegister];
      // Get the last state
      const previousState = newRegister.pop();

      // Double check that the previous state exists
      if (!previousState) return;

      // Unselect data (in the case we selected some)
      setSelectedData(previousState.selectedData);
      // And set the list to display
      setLessonListToShow(previousState.lessonListToShow);
      // Set the new navigation history without the previous state
      setNavRegister(newRegister);
      // And force set the content tab
      setSelectedTab(0);

      // Set the appropriate option buttons
      headerRef.current
        .lessons()
        .setOptionButtons(
          lessonInformation(previousState.lessonListToShow).parent,
          0
        );
      // And ensure the save button is not active
      headerRef.current
        .lessons()
        .setSaveButton({ show: false, disabled: true });
    };

    /**
     * Function applied when we press the edit button on cards
     */
    const handleCardClick = (
      record: Program | Course | Project | Module,
      selectedTab: number,
      lesson = lessonListToShow,
      addHistory: boolean = true
    ) => {
      if (record._id === selectedData?._id) return;
      // Execute this function only for master admins
      // if (userRole !== "master admin") return;
      // Check if the project contain information (videoUrl, planDocument)
      if (
        lesson === "projects" &&
        selectedTab === 0 &&
        (record.planDocument ||
          ("videoUrl" in record && record.videoUrl !== "") ||
          ("finalCodeName" in record && record.finalCodeName !== "") ||
          ("initialCodeName" in record && record.initialCodeName !== "")) &&
        "language" in record &&
        ([...programLanguagesList] as string[]).includes(record.language)
      ) {
        // check if we should forward to the editor
        return navigate(`/editor/projects/${record._id}`, {
          state: { navRegister },
        });
      }
      if (
        lesson === "modules" &&
        selectedTab === 0 &&
        "language" in record &&
        ([...programLanguagesList] as string[]).includes(record.language)
      ) {
        return navigate(`/editor/modules/${record._id}`, {
          state: { navRegister },
        });
      }

      // Get the lesson information
      const lessonInfo = lessonInformation(lesson);

      // Map to the child tab
      setLessonListToShow(lessonInfo.child);

      // And don't close tab search bar when it's a student (but for the rest, close it)
      lessonsRef.current.setShowTabSearchBar(["student"].includes(userRole));

      // Once clicked on card, add the information to the history
      if (addHistory) {
        setNavRegister([
          ...navRegister,
          { lessonListToShow: lesson, selectedData, record: record },
        ]);
      }

      // Hide the sidebar (in the customer admin and student case, just keep it)
      if (userRole === "master admin") setShowSideBarMenu(false);

      // And show tabs in every case but modules editing
      setShowTabs(userRole === "master admin" && lesson !== "modules");

      // Make sure we are not using from "Create New" button
      setFromCreateNew(false);

      // Select the current data
      setSelectedData(record);

      // Set the selected tab based on if it's expand or edit
      setSelectedTab(selectedTab);

      // Show the back button
      headerRef.current.setHeaderBackButton(true);
      // Remove the create new button
      headerRef.current.lessons().setShowCreateNewButton(false);

      // Add header option buttons just in case of master admin
      if (userRole === "master admin") {
        headerRef.current.lessons().setOptionButtons(lesson, selectedTab);
      }
    };

    /**
     * Expand click definition
     */
    const handleExpandClick = (record: Program | Course | Project | Module) => {
      return () => handleCardClick(record, 0);
    };

    /**
     * Edit click definition
     */
    const handleEditClick = (record: Program | Course | Project | Module) => {
      return (event: React.MouseEvent) => {
        // Prevent activate the click on the whole card
        event.stopPropagation();
        handleCardClick(record, 1);
      };
    };

    /**
     * Assign to classes click definition
     */
    const handleAddToClasses = (
      record: Program | Course | Project | Module,
      lesson: string
    ) => {
      return (event: React.MouseEvent) => {
        // Prevent activate the click on the whole card
        event.stopPropagation();
        // Show the add classes pop up
        setShowAddClasses(true);
        // And set the record to assign
        setLessonIdToAssignToClass(record._id);
        setLessonTypeToAssign(lesson);
        // Define the classes
        const classes = record.classes
          ? (record.classes.map((id) => {
              return { _id: id };
            }) as any)
          : [];
        setSelectedClasses(classes);
      };
    };

    /**
     * Function used to get the data depending what lesson we are looking
     */
    const getData = async () => {
      // First of all, set the isLoading state
      switch (lessonListToShow) {
        case "programs":
          setIsLoadingPrograms(true);
          setPrograms([]);
          break;
        case "courses":
          setIsLoadingCourses(true);
          setCourses([]);
          break;
        case "projects":
          setIsLoadingProjects(true);
          setProjects([]);
          break;
        case "modules":
          setIsLoadingModules(true);
          setModules([]);
          break;
      }

      // If there is a selected data, get the ids to show
      let response;
      if (selectedData) {
        let idList;
        if (lessonListToShow !== "courses") {
          // Done this way to avoid the TypeScript lint
          idList = selectedData[lessonListToShow as keyof typeof selectedData];
        } else {
          // In the particular case of courses, the program card is populating
          // its "course" field with more information, so it's necessary to get just
          // _id values For
          const courseList: any =
            selectedData[lessonListToShow as keyof typeof selectedData];

          // Get just the ids
          idList = courseList
            ? courseList.map((course: Course) => course._id)
            : [];
        }

        // Get all the child lesson that contains the id of the selected data
        const parentLesson = lessonInformation(lessonListToShow).parent

        // And get the response using this filter
        response = await getCollectionRequest(`/api/${lessonListToShow}`, [], {
          // _id: { $in: idList ? idList : [] },
          [parentLesson]:  {$in: [selectedData._id]}
        });
      } else {
        // Just collect the response without filtering
        response = await getCollectionRequest(`/api/${lessonListToShow}`);
      }

      // Check if the response was right
      if (!response.successful) {
        // Check if it's not successful because of a token expiration
        if (response.logout) {
          // alert(response.message);
          navigate("/");
        }

        return;
      }

      // Get the data
      const data = response.content;

      // And set to the corresponding state
      if (lessonListToShow === "programs") {
        setIsLoadingPrograms(false);
        setPrograms(data);
      }
      else if (lessonListToShow === "courses") {
        setIsLoadingCourses(false);
        setCourses(data);
      }
      else if (lessonListToShow === "projects") {
        setIsLoadingProjects(false);
        setProjects(data);
      }
      else if (lessonListToShow === "modules") {
        setIsLoadingModules(false);
        setModules(data);
      }
    };

    return (
      <div className="w-full h-full flex-col justify-start items-start gap-4 inline-flex overflow-x-hidden">
        {showTabs && (
          <TabBar
            options={tabsOptions}
            selectedTab={selectedTab}
            selectedLesson={lessonListToShow}
            fromCreateNew={fromCreateNew}
            setSelectedTab={setSelectedTab}
            headerRef={headerRef}
          />
        )}
        {selectedTab === 0 ? (
          <AnimatedLessonCards
            userRole={userRole}
            lessonListToShow={lessonListToShow}
            selectedData={selectedData}
            programs={programs}
            courses={courses}
            projects={projects}
            modules={modules}
            searchQuery={searchQuery}
            isLoadingPrograms={isLoadingPrograms}
            isLoadingCourses={isLoadingCourses}
            isLoadingProjects={isLoadingProjects}
            isLoadingModules={isLoadingModules}
            quizzesRef={quizzesRef}
            headerRef={headerRef}
            navRegister={navRegister}
            handleExpandClick={handleExpandClick}
            handleEditClick={handleEditClick}
            handleAddToClasses={handleAddToClasses}
            setSelectedModuleId={setSelectedModuleId}
            setShowAddToLesson={setShowAddToLesson}
            setLinkedId={setLinkedId}
            setLinkedLesson={setLinkedLesson}
          />
        ) : (
          <Settings
            ref={settingsRef}
            selectedData={selectedData}
            lessonListToShow={lessonListToShow}
            selectedLesson={lessonInformation(lessonListToShow).parent}
            linkedId={linkedId}
            linkedLesson={linkedLesson}
            afterCreateLessons={afterCreateLessons}
            headerRef={headerRef}
          />
        )}
        <PopUpPanel
          type="add-remove-child"
          selectedData={selectedData || undefined}
          selectedLesson={lessonListToShow}
          showPopUp={showAddPopUp}
          setShowPopUp={setShowAddPopUp}
          setLinkedId={setLinkedId}
          setLinkedLesson={setLinkedLesson}
          headerRef={headerRef}
          lessonsListRef={ref}
        />
        <PopUpPanel
          type="saved-successful"
          selectedLesson={successfulLesson}
          showPopUp={showSavedSuccessfully}
          setShowPopUp={setShowSavedSuccessfully}
          headerRef={headerRef}
        />
        <PopUpPanel
          type="assign-class"
          selectedLesson={lessonTypeToAssign}
          lessonIdToAssignToClass={lessonIdToAssignToClass}
          selectedClasses={selectedClasses}
          showPopUp={showAddClasses}
          setShowPopUp={setShowAddClasses}
        />
        <PopUpPanel
          type="assign-to-parent-lesson"
          selectedDataId={
            lessonListToShow === "modules" && selectedData === null
              ? selectedModuleId
              : selectedData?._id
          }
          selectedLesson={lessonListToShow}
          showPopUp={showAddToLesson}
          setShowPopUp={setShowAddToLesson}
          setLinkedId={setLinkedId}
          setLinkedLesson={setLinkedLesson}
          fromAddModuleCardButton={
            lessonListToShow === "modules" && selectedData === null
          }
          headerRef={headerRef}
        />
      </div>
    );
  }
);

export default LessonsList;
