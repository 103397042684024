import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Module, Project } from "utils/interfaces";
import JourneyBadge from "./JourneyBadge";
import { AnimatePresence, motion } from "framer-motion";

interface MyLearningJourneyElementProps {
  ref?: any;
  index: number;
  project: Project;
  toComplete: boolean;
  setUncompletedIndexes: React.Dispatch<React.SetStateAction<number[]>>;
}

const modulesPopUpVariants = {
  hidden: {
    opacity: 0,
    height: 0,
    width: 0,
  },
  show: {
    opacity: 1,
    height: "fit-content",
    width: "fit-content",
    transition: {
      delayChildren: 0.3,
    },
  },
};

const modulesPopUpItemsVariants = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
  },
};

const MyLearningJourneyElement: React.FC<MyLearningJourneyElementProps> =
  forwardRef(({ index, project, toComplete, setUncompletedIndexes }, ref) => {
    // Status definition
    const [modulesData, setModulesData] = useState<Module[]>([]);
    const [showModulesPopUp, setShowModulesPopUp] = useState(false);

    // Reference to the pop up menu
    const popUpRef = useRef<HTMLDivElement | null>(null);
    const badgeRef = useRef(null);

    /**
     * Functions and elements to expose to parents components
     */
    useImperativeHandle(ref, () => ({
      badge: badgeRef.current,
    }))

    /**
     * Function executed when clicking outside the referenced element
     * @param event
     */
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popUpRef.current &&
        !popUpRef.current.contains(event.target as Node)
      ) {
        setShowModulesPopUp(false);
      }
    };

    /**
     * Adding the listener for mousedown
     */
    useEffect(() => {
      // Attach event listener to document
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Remove event listener on cleanup
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    /**
     * Function used to load the modules data variable
     */
    useEffect(() => {
      // Get the modules inside the project
      const modules = project.modules;

      // Sort the modules based on the title
      const sortedModules =
        modules && modules.length > 0
          ? modules.sort((a: Module, b: Module) => {
              // Order the results based on the sum of the values
              // (they are on the same scale)
              if (a.title.toLocaleLowerCase() > b.title.toLocaleLowerCase())
                return 1;
              else return -1;
            })
          : [];

      // Assign them to the state variable
      setModulesData(sortedModules);
    }, []);

    return (
      <div className="w-fit h-fit relative" ref={popUpRef}>
        <JourneyBadge
          ref={badgeRef}
          index={index}
          type="projects"
          lesson={project}
          toComplete={toComplete}
          setUncompletedIndexes={setUncompletedIndexes}
          setShowModulesPopUp={setShowModulesPopUp}
        />
        <AnimatePresence>
          {showModulesPopUp && (
            <motion.div
              variants={modulesPopUpVariants}
              initial="hidden"
              animate="show"
              exit="hidden"
              transition={{
                duration: 0.3,
                ease: "easeInOut",
              }}
              className={`absolute top-8 ${
                index % 3 === 0
                  ? "left-3 origin-top-left "
                  : index % 3 === 1
                  ? "left-1/2 origin-top -translate-x-1/2 "
                  : "right-3 origin-top-right "
              }p-2 border border-neutral-300 rounded-lg bg-neutral-50/20 backdrop-blur-sm w-fit h-fit z-10`}
            >
              <motion.div
                variants={modulesPopUpItemsVariants}
                className="w-full h-full inline-flex duration-300"
              >
                {modulesData && modulesData.length > 0 ? (
                  modulesData.map((module: Module, moduleIndex: number) => (
                    <JourneyBadge
                      key={moduleIndex}
                      index={moduleIndex}
                      type="modules"
                      lesson={module}
                      toComplete={false}
                      setUncompletedIndexes={() => {}}
                      setShowModulesPopUp={() => {}}
                    />
                  ))
                ) : (
                  <></>
                )}
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  });

export default MyLearningJourneyElement;
