import { createContext } from "react";

// Define a context for the main element
export const MainContext = createContext<any>(null);
export const CustomersContext = createContext<any>(null);
export const LessonsContext = createContext<any>(null);
export const EditorContext = createContext<any>(null);
export const EditorLeftPaneContext = createContext<any>(null);
export const ViewHeaderContext = createContext<any>(null);
export const MinecraftEditorContext = createContext<any>(null);
