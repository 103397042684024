/**
 * optionValues.ts
 * -----------------
 * In this file, we define some of the options used for the forms.
 * Consumed by directories such as:
 * - routes/signup
 * - routes/new-program
 * - routes/new-quiz
 */

export const genderList = ["Male", "Female", "Other"];
export const levelList = [
  "Introduction",
  "Beginner",
  "Intermediate",
  "Advanced",
];
export const ageList = ["5+", "13+"];
export const heritageList = ["Aboriginal & Torres Straight Islander", "-"];
export const questionTypeList = [
  "Short Answer",
  "Multiple Choice",
  "Multi-Select",
  "True or False",
];
export const trueOrFalseList = ["True", "False"];
export const programLanguagesList = ["Scratch", "JavaScript", "Python", "Minecraft"] as const;
