import React, { useState } from "react";
import { MdOutlineSend } from "react-icons/md";

interface ChatMessageInputParams {
  inputEnabled: boolean;
  handleSend: (text: string) => any;
}

const ChatMessageInput: React.FC<ChatMessageInputParams> = ({
  inputEnabled,
  handleSend,
}) => {
  const [inputText, setInputText] = useState<string>("");

  const send = () => {
    handleSend(inputText.trim());
    setInputText("");
  };

  return (
    <div className="h-[70px] w-full bg-Canvas rounded-xl justify-start items-center gap-2 inline-flex relative">
      <div className="w-full h-full bg-neutral-50 rounded-xl justify-start items-center gap-2 inline-flex">
        <input
          className="w-full h-full p-4 pr-[76px] text-neutral-700 placeholder:text-neutral-300 text-lg font-normal font-sans leading-snug bg-transparent border-none focus:outline-none"
          type="text"
          value={inputText}
          onChange={(event) => setInputText(event.target.value)}
          disabled={inputEnabled}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              send();
            }
          }}
          placeholder={inputEnabled ? "" : "Please enter your question here"}
        />
      </div>
      <div
        className="group absolute right-4 p-3 rounded-[32px] justify-start items-center gap-2 flex cursor-pointer hover:bg-neutral-100 ease-in-out duration-150"
        onClick={send}
      >
        <div className="w-7 h-7 relative">
          <MdOutlineSend className="w-full h-full fill-neutral-300 group-hover:fill-neutral-500 ease-in-out duration-150" />
        </div>
      </div>
    </div>
  );
};

export default ChatMessageInput;
