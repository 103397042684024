import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useLayoutEffect,
  useContext,
} from "react";
import { throttle } from "lodash";
import { motion } from "framer-motion";
import {
  ImperativePanelGroupHandle,
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from "react-resizable-panels";
import smoothResize from "components/Editor/utils/smoothResize";
import ResizeableSash from "assets/icons/editor/ResizeableSashIcon";
import {
  EditorContext,
  MinecraftEditorContext,
} from "components/main-view/utils/Contexts";
import RightPane from "./RightPane/RightPane";
import LeftPane from "./LeftPane/LeftPane";
import { updateResults } from "services/resultsRequests";
import { useParams } from "react-router-dom";

interface MinecraftEditorProps {
  video: string;
  videoTranscript: string;
  pdf: string;
  toShrink: boolean;
  hide: boolean;
}

const MinecraftEditor: React.FC<MinecraftEditorProps> = ({
  video,
  videoTranscript,
  pdf,
  toShrink,
  hide,
}) => {
  /**
   * Paramters
   */
  // Percentage of the video watched to declare completion
  const videoPercentageThreshold = 0.95;

  // Width definition
  const codeEditorWidth = window.innerWidth - 40; // Less the separator

  // Panels size limits
  const minLeftPaneWidth = (297 / codeEditorWidth) * 100; // minimum left panel width in px
  const closedMinRightPaneWidth = (90 / codeEditorWidth) * 100; // minimum right panel width in px
  const openMinRightPaneWidth = (440 / codeEditorWidth) * 100; // minimum right panel width in px

  // Get more parameters
  const { zeroThreshold } = useContext(EditorContext);

  /**
   * MinecraftEditor Init
   */
  // States definition
  const [leftRightSizes, setLeftRightSizes] = useState([
    100 - closedMinRightPaneWidth,
    closedMinRightPaneWidth,
  ]);
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [normalisedLeftWidth, setNormalisedLeftWidth] = useState<number>(1);
  const [normalisedRightWidth, setNormalisedRightWidth] = useState<number>(0);
  const [scaleFactor, setScaleFactor] = useState(1);
  const [scaledRightOffset, setScaledRightOffset] = useState(0);
  const [scaledTopOffset, setScaledTopOffset] = useState(0);
  const [minRightPaneWidth, setMinRightPaneWidth] = useState(
    closedMinRightPaneWidth
  );
  const [markCompleted, setMarkCompleted] = useState(false);

  // References definition
  const consoleBottomRef = useRef<HTMLDivElement>(null);
  const rightPanelRef = useRef<any>(null); // To define the type of this element
  const editorPanesRef = useRef<HTMLDivElement>(null);
  const leftRightLayout = useRef<ImperativePanelGroupHandle>(null);

  // Get the url parameters
  const { moduleOrProject, id } = useParams();

  // Get the limit pixel of the animation
  const startAnimRight = Math.round(
    Math.abs(openMinRightPaneWidth - closedMinRightPaneWidth)
  );

  useLayoutEffect(() => {
    const handleResize = () => {
      const targetWidth = 180;
      const originalWidth = document.body.clientWidth - 96; // 96 is x padding
      const widthScale = targetWidth / originalWidth;
      const targetHeight = 111;
      const originalHeight = document.body.clientHeight - 155; // 155 is top offset
      const heightScale = targetHeight / originalHeight;
      const rightOffset = (targetWidth - originalWidth * heightScale) / 2;
      const topOffset = (targetHeight - originalHeight * widthScale) / 2;

      if (widthScale < heightScale) {
        setScaleFactor(widthScale);
        setScaledTopOffset(topOffset);
        setScaledRightOffset(0);
      } else {
        setScaleFactor(heightScale);
        setScaledTopOffset(0);
        setScaledRightOffset(rightOffset);
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /*
   * throttle console scroll to bottom
   */
  const throttleScroll = throttle(() => {
    // Apply only when left pane is open (otherwise the console opening
    // animation goes wrong), and when we finished moving the pane
    if (normalisedLeftWidth === 1 && !isResizing) {
      consoleBottomRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, 100); // Execute at most once per 100ms

  /**
   * Function used to send the signal for finished class once the 
   * mark completed signal is triggered
   */
  useEffect(() => {
    if (markCompleted && moduleOrProject && id) {
      console.log("Lesson finished!");
      updateResults(moduleOrProject, id, "finished");
    }
  }, [markCompleted]);

  /*
   * scroll console to bottom
   */
  useEffect(() => {
    throttleScroll();
    return () => throttleScroll.cancel();
  }, [throttleScroll]);

  /*
   * Calculate normalised left and right width for close animations
   */
  useEffect(() => {
    // Calculate the normalised values
    let normLeftWidth = Math.min(
      Math.max(leftRightSizes[0] - minLeftPaneWidth, 0),
      1
    );
    let normRightWidth = Math.min(
      Math.max(
        (leftRightSizes[1] - closedMinRightPaneWidth) / startAnimRight,
        0
      ),
      1
    );

    // Make sure that under a threshold, the numbers are zero
    normLeftWidth = normLeftWidth < zeroThreshold ? 0 : normLeftWidth;
    normRightWidth = normRightWidth < zeroThreshold ? 0 : normRightWidth;

    // Set the states
    setNormalisedLeftWidth(normLeftWidth);
    setNormalisedRightWidth(normRightWidth);
  }, [leftRightSizes]);

  /**
   * Function to update the open and close state based on the normalised value
   */
  useEffect(() => {
    if (normalisedRightWidth >= 1)
      setMinRightPaneWidth(openMinRightPaneWidth + 1);
    else setMinRightPaneWidth(closedMinRightPaneWidth);
  }, [normalisedRightWidth]);

  /**
   * Set automatically the pane sizes when the editor is reaching the limit
   */
  const handlePanesResizeWidthEnd = useCallback(() => {
    // Apply this animation just if we are opening but we haven't finished opening
    if (normalisedRightWidth === 1) return;

    // Aconditionate the numbers
    // 1. To correct from the bias of taking the current values
    // 2. To not exceed the limits
    const leftPaneWidth = Math.max(minLeftPaneWidth, leftRightSizes[0]);
    const rightPaneWidth = Math.max(minRightPaneWidth, leftRightSizes[1]);

    // Create the current size array for the smoothResize function
    const currentSizes = [leftPaneWidth, rightPaneWidth];

    // Right pane logic
    if (
      rightPaneWidth < openMinRightPaneWidth &&
      rightPaneWidth !== closedMinRightPaneWidth
    ) {
      smoothResize(
        [
          leftPaneWidth + rightPaneWidth - closedMinRightPaneWidth,
          closedMinRightPaneWidth,
        ],
        currentSizes,
        leftRightLayout,
        100
      );
    }
  }, [leftRightSizes, smoothResize, normalisedRightWidth]);

  /**
   * Function used when clicking the right pane when it's closed
   */
  const handleOpenClick = useCallback(() => {
    // Check that the tab is closed to apply this effect
    if (normalisedRightWidth > 0) return;

    // Aconditionate the numbers
    // 1. To correct from the bias of taking the current values
    // 2. To not exceed the limits
    const leftPaneWidth = Math.max(minLeftPaneWidth, leftRightSizes[0]);
    const rightPaneWidth = Math.max(minRightPaneWidth, leftRightSizes[1]);

    // Create the current size array for the smoothResize function
    const currentSizes = [leftPaneWidth, rightPaneWidth];

    // Maximize Right pane logic
    smoothResize(
      [minLeftPaneWidth, leftPaneWidth + rightPaneWidth - minLeftPaneWidth],
      currentSizes,
      leftRightLayout,
      300
    );
  }, [leftRightLayout, smoothResize, leftRightSizes, normalisedRightWidth]);

  /**
   * Function used when clicking the close button on the right pane
   */
  const handleCloseClick = useCallback(() => {
    // Modify the limit
    setMinRightPaneWidth(closedMinRightPaneWidth);

    // Aconditionate the numbers
    // 1. To correct from the bias of taking the current values
    // 2. To not exceed the limits
    const leftPaneWidth = Math.max(minLeftPaneWidth, leftRightSizes[0]);
    const rightPaneWidth = Math.max(closedMinRightPaneWidth, leftRightSizes[1]);

    // Create the current size array for the smoothResize function
    const currentSizes = [leftPaneWidth, rightPaneWidth];

    // Maximize left pane logic
    smoothResize(
      [
        leftPaneWidth + rightPaneWidth - closedMinRightPaneWidth,
        closedMinRightPaneWidth,
      ],
      currentSizes,
      leftRightLayout,
      250
    );
  }, [
    leftRightLayout,
    smoothResize,
    leftRightSizes,
    normalisedRightWidth,
    closedMinRightPaneWidth,
  ]);

  return (
    <MinecraftEditorContext.Provider
      value={{ normalisedRightWidth, videoPercentageThreshold }}
    >
      <motion.div
        className={`w-screen h-[calc(100vh-155px)] overflow-hidden absolute top-0 ${
          toShrink || hide ? "-z-10" : "z-10"
        }`}
      >
        <motion.div
          className="h-[calc(100vh-155px)] w-full absolute right-0 origin-top-right"
          ref={editorPanesRef}
          initial={{
            height: "calc(100vh-155px)",
            transform: "translateX(0%) scale(1)",
            top: 0,
            right: 0,
            opacity: 1,
          }}
          animate={{
            transform: toShrink
              ? `translateX(0%) scale(${scaleFactor * 0.98})`
              : `translateX(0%) scale(1)`,
            top: hide ? "100%" : toShrink ? 24 + scaledTopOffset : 0,
            right: toShrink ? 48 + scaledRightOffset : 0,
            opacity: hide ? 0 : 1,
            transition: { ease: "linear" },
          }}
        >
          <PanelGroup
            ref={leftRightLayout}
            direction="horizontal"
            className="pb-8 relative"
            onLayout={(sizes) => setLeftRightSizes(sizes)}
          >
            <Panel
              minSize={minLeftPaneWidth}
              className="w-full h-full overflow-visible"
              defaultSize={100 - closedMinRightPaneWidth}
            >
              <LeftPane
                leftRightSizes={leftRightSizes}
                codeEditorWidth={codeEditorWidth}
                video={video}
                setMarkCompleted={setMarkCompleted}
              />
            </Panel>
            <PanelResizeHandle
              onDragging={(isDragging: boolean) => {
                if (isDragging) setIsResizing(true);
                else {
                  setIsResizing(false);
                  handlePanesResizeWidthEnd();
                }
              }}
            >
              <div className="inline-flex h-full items-center pb-8">
                <ResizeableSash />
              </div>
            </PanelResizeHandle>
            <Panel
              minSize={minRightPaneWidth}
              className="w-full h-full overflow-visible"
            >
              <RightPane
                pdf={pdf}
                videoTranscript={videoTranscript}
                isResizing={isResizing}
                rightPanelRef={rightPanelRef}
                handleOpenClick={handleOpenClick}
                handleCloseClick={handleCloseClick}
              />
            </Panel>
          </PanelGroup>
        </motion.div>
      </motion.div>
    </MinecraftEditorContext.Provider>
  );
};

export default MinecraftEditor;
