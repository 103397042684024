import { motion } from "framer-motion";
import ButtonBar from "../utils/ButtonBar";
import OutputView from "./OutputView";
import { useEffect, useState } from "react";
import PDFViewer from "components/Editor/utils/PDFViewer";

interface RightPaneType {
  normalisedRightWidth: number;
  showOutputRunCode: boolean;
  setActiveOutput: any;
  activeOutput: string;
  srcDoc: string;
  iframeRef: React.RefObject<HTMLIFrameElement>;
  iframeReloadTrigger: number;
  hasPdf: boolean;
  handleRunCode: () => void;
  isResizing: boolean;
  rightPanelRef: React.RefObject<HTMLDivElement>;
}

const RightPane: React.FC<RightPaneType> = ({
  normalisedRightWidth,
  showOutputRunCode,
  setActiveOutput,
  activeOutput,
  srcDoc,
  iframeRef,
  iframeReloadTrigger,
  hasPdf,
  handleRunCode,
  isResizing,
  rightPanelRef,
}) => {
  // States definition
  const [rgbOutputText, setRgbOutputText] = useState<number>(0);

  /*
   * Set text RGB text-color for the Output label
   */
  useEffect(() => {
    setRgbOutputText(Math.round(255 * (1 - normalisedRightWidth)));
  }, [normalisedRightWidth]);

  const rightPaneButtons = hasPdf ? ["Output", "Instructions"] : ["Output"];

  return (
    <div
      className="flex flex-row w-full h-full mr-12 rounded-xl"
      ref={rightPanelRef}
    >
      <div className="flex-1 pt-0 bg-neutral-800 h-full overflow-hidden rounded-xl">
        <motion.div
          className="flex-1 flex flex-col h-full overflow-hidden"
          style={{ opacity: normalisedRightWidth }}
        >
          <ButtonBar
            id="output"
            text={rightPaneButtons}
            onSelect={setActiveOutput}
            activeTab={activeOutput}
            hasAdd={false}
            hasOptions={false}
            negativeTabColor={true}
            handleRunCode={handleRunCode}
            showRunCode={showOutputRunCode}
          />
          <OutputView
            srcDoc={srcDoc}
            iframeRef={iframeRef}
            iframeReloadTrigger={iframeReloadTrigger}
            // handleRunCode={handleRunCode}
            isVisible={activeOutput === "Output"}
            isResizing={isResizing}
          />
          {hasPdf &&
          <div className="w-full h-full p-4 pt-0">
            <PDFViewer
              isPaning={isResizing}
              isVisible={activeOutput === "Instructions"}
            />
          </div>
          }
        </motion.div>
      </div>
      <div className="flex-none w-12 h-full ml-auto">
        <div className="w-fit h-fit translate-x-[1.5rem] select-none">
          <motion.div
            style={{
              originX: 0,
              originY: 0,
              rotate: 90,
              opacity: -0.5 * normalisedRightWidth + 1,
              translateX: Math.round((normalisedRightWidth - 1) * 31),
              translateY: Math.round((1 - normalisedRightWidth) * 15),
              color: `rgb(${rgbOutputText},${rgbOutputText},${rgbOutputText})`,
              transition: "transform 0.05s ease-in-out",
            }}
          >
            Output
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default RightPane;
