interface TabBarProps {
  tabOptions: string[];
  selectedTabIndex: number;
  setSelectedTabIndex: React.Dispatch<React.SetStateAction<number>>;
}

const TabBar: React.FC<TabBarProps> = ({
  tabOptions,
  selectedTabIndex,
  setSelectedTabIndex,
}) => {
  /**
   * Function used when clicking a tab
   * @param index
   * @returns
   */
  const handleClickTabItem = (index: number) => {
    return () => setSelectedTabIndex(index);
  };

  return (
    <div className="w-fit h-fit min-w-fit p-1.5 bg-neutral-100 rounded-full justify-center items-start gap-1.5 inline-flex">
      {tabOptions.map((item: string, index: number) => (
        <div
          key={index}
          className={`self-stretch px-3 py-1.5 ${
            selectedTabIndex === index
              ? "bg-white "
              : "hover:bg-neutral-200 ease-in-out duration-150 "
          }rounded-full justify-center items-center gap-2 inline-flex cursor-pointer`}
          onClick={handleClickTabItem(index)}
        >
          <div className="text-dc-secondary-600 text-sm font-semibold font-sans leading-[17px] select-none whitespace-nowrap break-keep">
            {item}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TabBar;
