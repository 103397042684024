import { ArrayElement, Course } from "utils/interfaces";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import MyLearningJourneyElement from "./MyLearningJourneyElement";
import { platform } from "config/platform";

interface MyLearningJourneyPanelProps {
  ref?: any;
  course: Course
}

const MyLearningJourneyPanel: React.FC<MyLearningJourneyPanelProps> =
  forwardRef(({ course }, ref) => {
    // States definition
    const [uncompletedIndexes, setUncompletedIndexes] = useState<number[]>([]);
    const [hereIndex, setHereIndex] = useState<number | null>(null);

    // Define a reference to each project badge inside this panel
    const projectsRef = useRef<{ [key: number]: any }>({});

    /**
     * Functions and elements to expose to parents components
     */
    useImperativeHandle(ref, () => ({
      pressCurrentProject() {
        pressCurrentProject();
      },
    }));

    /**
     * Function to define the indexes of the lessons that are uncompleted
     * on this panel
     */
    useEffect(() => {
      // Define the current index
      if (uncompletedIndexes.length > 0) {
        setHereIndex(Math.min(...uncompletedIndexes));
      } else {
        setHereIndex(null);
      }
    }, [uncompletedIndexes]);

    /**
     * Function used to pass to the parent element to allow
     * clicking on the current lesson
     */
    const pressCurrentProject = () => {
      // Get the current project ref
      const currentProjectRef = projectsRef.current[hereIndex || 0];

      // Click that project
      currentProjectRef.badge.handleClickBadge();
    }

    return (
      <div className="grow-0 shrink-0 w-full min-w-0 flex flex-col gap-2">
        <div className="self-stretch text-neutral-500 text-xs font-normal font-sans leading-[14px] select-none">
          {course.title || "..."}
        </div>
        <div className="w-full h-full pt-4 custom-scroll overflow-x-hidden overflow-y-auto z-10">
          {/*overflow-y-auto*/}
          <div
            className={`w-full justify-items-center grid grid-cols-3 gap-6 ${
              platform === "deadly-coders" ? "gap-y-12" : "gap-y-7"
            }`}
          >
            {course.projects && course.projects.length > 0 ? (
              course.projects.map(
                (
                  project: ArrayElement<typeof course.projects>,
                  index: number
                ) => (
                  <MyLearningJourneyElement
                    key={index}
                    ref={(element: any) => {
                      projectsRef.current[index] = element;
                    }}
                    index={index}
                    project={project as any}
                    toComplete={index === hereIndex}
                    setUncompletedIndexes={setUncompletedIndexes}
                  />
                )
              )
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  });

export default MyLearningJourneyPanel;
