import React, {
  useEffect,
  useState,
  useLayoutEffect,
  MutableRefObject,
} from "react";
import { AnimatePresence, motion } from "framer-motion";
import { CodeFile } from "utils/interfaces";
import { LoadingDots } from "utils/loadingAnimation";
import { convertJsonToScratch } from "services/assistantRequests";

interface ScratchEditorProps {
  sb3File?: ArrayBuffer | null;
  videoExpanded: boolean;
  setIsResizingOutput: (isResizing: boolean) => void;
  downloadSignal: boolean;
  setDownloadSignal: (signal: boolean) => void;
  restoreSpriteSignal: boolean;
  setRestoreSpriteSignal: (signal: boolean) => void;
  toggleTurboModeSignal: boolean;
  setToggleTurboMode: (signal: boolean) => void;
  iframeRef: MutableRefObject<HTMLIFrameElement | null>;
  onFileChange: (files: CodeFile[]) => void;
}

const ScratchEditor: React.FC<ScratchEditorProps> = ({
  sb3File,
  videoExpanded,
  setIsResizingOutput,
  downloadSignal,
  setDownloadSignal,
  restoreSpriteSignal,
  setRestoreSpriteSignal,
  toggleTurboModeSignal,
  setToggleTurboMode,
  iframeRef,
  onFileChange,
}) => {
  const iframeSrc =
    process.env.REACT_APP_SCRATCH_URL || "http://localhost:8602";

  // States definition
  const [guiReady, setGuiReady] = useState(false);
  const [currentSb3File, setCurrentSb3File] = useState<ArrayBuffer | null>(
    null
  );
  
  const [scaleFactor, setScaleFactor] = useState(1);
  const [scaledTopOffset, setScaledTopOffset] = useState(0);
  const [scaledRightOffset, setScaledRightOffset] = useState(0);
  useEffect(() => {
    onFileChange([]);
  }, []);

  useEffect(() => {
    const handleMessage = async (event: MessageEvent) => {
      switch (event.data.type) {
        case "SCRATCH_GUI_READY":
          if (!guiReady) setGuiReady(true);
          break;
        case "SCRATCH_JSON":
          console.log(event.data.json);
          const blocks = await convertJsonToScratch(event.data.json);
          console.log("blocks", blocks);
          onFileChange([{ name: "main.sb3", content: blocks }]);
          break;
        case "PROJECT_LOADED":
          console.log("Project loaded successfully in GUI");
          break;
        case "PROJECT_LOAD_ERROR":
          console.error("Error loading project in GUI:", event.data.error);
          break;
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [guiReady]);

  useEffect(() => {
    if (sb3File !== undefined && sb3File !== currentSb3File) {
      setCurrentSb3File(sb3File);
    }
  }, [sb3File, currentSb3File]);

  useEffect(() => {
    if (guiReady && currentSb3File) {
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(
          {
            type: "LOAD_PROJECT",
            projectData: currentSb3File,
          },
          "*"
        );
      }
    }
  }, [guiReady, currentSb3File]);

  // For resizing Scratch IFrame
  useLayoutEffect(() => {
    const handleResize = () => {
      const targetWidth = 180;
      const originalWidth = iframeRef?.current?.clientWidth || 0; // 0 x padding
      const widthScale = targetWidth / originalWidth;
      const targetHeight = 111;
      const originalHeight = iframeRef?.current?.clientHeight || 0 - 155; // 125 is top offset
      const heightScale = targetHeight / originalHeight;
      const rightOffset = (targetWidth - originalWidth * heightScale) / 2;
      const topOffset = (targetHeight - originalHeight * widthScale) / 2;

      if (widthScale < heightScale) {
        setScaleFactor(widthScale);
        setScaledTopOffset(topOffset);
        setScaledRightOffset(0);
      } else {
        setScaleFactor(heightScale);
        setScaledTopOffset(0);
        setScaledRightOffset(rightOffset);
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (downloadSignal) {
      downloadProject();
      setDownloadSignal(false); // Reset the signal after handling
    }
  }, [downloadSignal, setDownloadSignal]);

  const downloadProject = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage({ type: "DOWNLOAD_PROJECT" }, "*");
    }
  };

  useEffect(() => {
    if (restoreSpriteSignal) {
      restoreSprite();
      setRestoreSpriteSignal(false); // Reset the signal after handling
    }
  }, [restoreSpriteSignal, setRestoreSpriteSignal]);

  const restoreSprite = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage({ type: "RESTORE_DEFAULT_SPRITE" }, "*");
    }
  };

  useEffect(() => {
    if (toggleTurboModeSignal) {
      turboMode();
      setToggleTurboMode(false); // Reset the signal after handling
    }
  }, [toggleTurboModeSignal, setToggleTurboMode]);

  const turboMode = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage({ type: "TOGGLE_TURBO_MODE" }, "*");
    }
  };

  // const changeLanguage = () => {
  //   const iframe = iframeRef.current;
  //   if (iframe && iframe.contentWindow) {
  //     iframe.contentWindow.postMessage({ type: 'LANGUAGE_CHANGED', language: 'es' }, '*');
  //   }
  // }

  // Not working atm
  const changeTheme = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        { type: "CHANGE_THEME", theme: "HIGH_CONTRAST_THEME" },
        "*"
      );
    }
  };

  return (
    <motion.div
      className="w-screen h-[calc(100vh-125px)] overflow-hidden absolute top-[-30px] left-0"
      style={{
        zIndex: videoExpanded ? -10 : "auto",
      }}
    >
      <motion.div
        className="w-full h-full absolute origin-top-right"
        initial={{
          transform: "translateX(0%) scale(1)",
          top: 0,
          right: 0,
        }}
        animate={{
          transform: videoExpanded
            ? `scale(${scaleFactor * 0.98})`
            : "scale(1)",
          top: videoExpanded
            ? +48 + scaledTopOffset + (scaleFactor * 30) / 2
            : 0,
          right: videoExpanded ? 54 + scaledRightOffset : 0,
          transition: { ease: "linear" },
        }}
        onAnimationStart={() => setIsResizingOutput(true)}
        onAnimationEnd={() => setIsResizingOutput(false)}
      >
        <div className="relative w-full h-full pointer-events-auto flex flex-col">
          {/* <div className="h-px bg-gray-300 w-full"></div> */}
          {/* <button className='text-large' onClick={changeTheme}> BUTTON </button> */}
          <AnimatePresence>
            {!guiReady && (
              <motion.div
                className="absolute w-full h-full flex justify-center items-center backdrop-blur-sm"
                initial={{
                  backdropFilter: "blur(4px)",
                  opacity: 1,
                }}
                animate={{
                  backdropFilter: guiReady ? "blur(0px)" : "blur(4px)",
                  opacity: guiReady ? 0 : 1,
                }}
                exit={{
                  backdropFilter: "blur(0px)",
                  opacity: 0,
                }}
              >
                <LoadingDots bgColor="bg-dc-secondary-600" />
              </motion.div>
            )}
          </AnimatePresence>
          <iframe
            ref={iframeRef}
            id="scratch-gui"
            src={iframeSrc}
            className="w-full border-none"
            style={{ height: "100%" }}
            allowFullScreen
            title="Scratch Editor"
          />
        </div>
      </motion.div>
    </motion.div>
  );
};

export default ScratchEditor;
