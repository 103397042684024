import { editor } from "monaco-editor";
type editortheme = editor.IStandaloneThemeData;

const colors = {
  "editor.background": "#00000000",
  "editor.selectionBackground": "#00000016",
  "editor.lineHighlightBackground": "#0000000a",
  "scrollbarSlider.background": "#999",
  "scrollbarSlider.hoverBackground": "#777",
};

const rules: any = [
  // { token: "identifier", foreground: "#7A300B"}
];

export const theme: editortheme = {
  base: "vs",
  inherit: true,
  rules: rules,
  colors: colors,
};
