import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import SchoolCard from "./cards/SchoolCard";
import { getCollectionRequest } from "services/apiRequests";
import { SchoolListType } from "utils/interfaces";
import { MdCorporateFare } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import EmptyView from "./utils/EmptyView";
import { LoadingDots } from "utils/loadingAnimation";

interface CustomersListProps {
  ref: any;
  searchQuery: string;
  selectedData: SchoolListType | null;
  setSelectedData: React.Dispatch<React.SetStateAction<SchoolListType | null>>;
  setDisplay: React.Dispatch<
    React.SetStateAction<"main" | "create-edit" | "inside-school">
  >;
  headerRef: React.MutableRefObject<any>;
  from?: "main" | "dashboard";
}

const CustomersList: React.FC<CustomersListProps> = forwardRef(
  (
    {
      searchQuery,
      selectedData,
      setSelectedData,
      setDisplay,
      headerRef,
      from = "dashboard",
    },
    ref
  ) => {
    // States definition
    const [data, setData] = useState<SchoolListType[]>([]);
    const [isLoadingData, setIsLoadingData] = useState(true);

    // Define a navigation hook
    const navigate = useNavigate();

    /**
     * Define the list of functions that can be used from external components
     * using a reference
     */
    useImperativeHandle(ref, () => ({
      getData() {
        getData();
      },
      async setSchoolToShow(id: string, currentActiveItem: string) {
        // First, select the data
        const selectedData = await getData({ _id: id });

        // And show the create school view
        clickEdit(selectedData[0]);

        // And set the objective item after press back
        headerRef.current.customers().setActiveItemOnBack(currentActiveItem);
      },
    }));

    /**
     * Root definition
     */
    useEffect(() => {
      getData();
    }, [selectedData]);

    /**
     * Function applied when we click on the card
     */
    const handleCardClick = (card: SchoolListType) => {
      return (event: React.MouseEvent) => {
        // Prevent activate the click on the whole card
        event.stopPropagation();

        // Select the current data
        setSelectedData(card);
        // Set the customers panels
        setDisplay("inside-school");

        // Show the back button
        headerRef.current.setHeaderBackButton(true);
        // Define the type of the show list
        headerRef.current.dashboard().setCreateTypeMasterAdmin("classes");
        // Set the title and description
        headerRef.current.setHeaderTitle(card.name);
        headerRef.current.setHeaderDescription(
          <div className="w-fit flex gap-1">
            <MdCorporateFare />
            <div>School Panel</div>
          </div>
        );
      };
    };

    /**
     * Function applied when we press the edit button on cards
     * @param card
     */
    const clickEdit = (card: SchoolListType) => {
      // Select the current data
      setSelectedData(card);

      // Show the back button
      headerRef.current.setHeaderBackButton(true);

      // Define the current handler
      let currentHandler;
      if (from === "main") {
        currentHandler = headerRef.current.customers();
      } else if (from === "dashboard") {
        currentHandler = headerRef.current.dashboard();
      }

      // Set the same view as when creating a school
      currentHandler.pressEditSchool(card.name, "School Profile");
    };

    /**
     * Wrapper to transfor the clickEdit function into a handler
     */
    const handleClickEdit = (card: SchoolListType) => {
      return (event: React.MouseEvent) => {
        // Prevent activate the click on the whole card
        event.stopPropagation();

        clickEdit(card);
      };
    };

    /**
     * Function to get the school data
     * @returns data
     */
    const getData = async (filter = {}) => {
      // Set loading animation
      setIsLoadingData(true);

      // Define the population option
      const response = await getCollectionRequest(
        "/api/organisations",
        [],
        filter
      );

      // Check if the response is successful
      if (!response.successful) {
        // Check if it's not successful because of a token expiration
        if (response.logout) {
          // alert(response.message);
          navigate("/");
        }

        return [];
      }

      // Get the data
      const data = response.content;

      // Assign the data to the corresponding element
      setData(data);

      // Stop loading animation
      setIsLoadingData(false);

      return data;
    };

    /**
     * Function that renders the list of cards depending of the activeClass selected
     * @returns a list of cards
     */
    const cardsToRender = () => {
      return data && data.length > 0 ? (
        data
          .filter((item: any) =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
          )
          .map((school: any, index: number) => (
            <SchoolCard
              key={index}
              school={school}
              handleCardClick={handleCardClick}
              handleClickEdit={handleClickEdit}
              averageProgress={0}
              aptitude={0}
            />
          ))
      ) : !isLoadingData ? (
        <EmptyView headerRef={headerRef} />
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <LoadingDots bgColor="bg-neutral-300" />
        </div>
      );
    };

    return (
      <div className="w-full h-full flex-col justify-start items-start gap-4 inline-flex overflow-y-auto custom-scroll">
        {cardsToRender()}
      </div>
    );
  }
);

export default CustomersList;
